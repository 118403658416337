import { useEffect } from 'react';
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createHashRouter,
  createRoutesFromChildren,
} from 'react-router-dom';
import { store, useAppDispatch, useAppSelector } from 'store';
import config from 'env';

import PrivateRoute, { PrivateRouteProps } from 'components/PrivateRoute';
import PublicRoute, { PublicRouteProps } from 'components/PublicRoute';
import MfaRoute from 'components/MfaRoute';

import { VLoader } from 'components/VLoader';
import { Route as RouteType, routes } from 'nav';
import {
  logout,
  setHasBeenRedirectedOnLogin,
} from 'store/reducers/user/user.slice';
import { useLazyGetProfileQuery } from 'store/api/profiles.api';
import ErrorElement from 'layouts/ErrorElement';
import { setupNotifications } from 'utils/init-firebase';
import { setupListeners } from '@reduxjs/toolkit/query';

const defaultPrivateRouteProps: Omit<PrivateRouteProps, 'outlet'> = {
  redirectPath: routes.public.login.href,
};

const defaultPublicRouteProps: Omit<PublicRouteProps, 'outlet'> = {
  redirectPath: routes.private.patientsOverview.href,
};

const defaultAdminRouteProps: Omit<PrivateRouteProps, 'outlet'> = {
  redirectPath: routes.private.patientsOverview.href,
};

const getRouterPathFromRoute = (route: RouteType): string =>
  route.routerPath || (typeof route.href === 'string' ? route.href : '/');

const router = // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ((window as any).cordova ? createHashRouter : createBrowserRouter)(
    createRoutesFromChildren([
      ...Object.values(routes.private).map((route: RouteType, index) => (
        <Route
          key={index}
          path={getRouterPathFromRoute(route)}
          errorElement={<ErrorElement />}
          element={
            <PrivateRoute
              {...defaultPrivateRouteProps}
              outlet={route.Outlet}
              withoutLayout={route.withoutLayout}
            />
          }
        />
      )),
      ...Object.values(routes.public).map((route, index) => (
        <Route
          key={index}
          path={getRouterPathFromRoute(route)}
          errorElement={<ErrorElement />}
          element={
            <PublicRoute {...defaultPublicRouteProps} outlet={route.Outlet} />
          }
        />
      )),
      ...Object.values(routes.admin).map((route, index) => (
        <Route
          key={index}
          path={getRouterPathFromRoute(route)}
          errorElement={<ErrorElement />}
          element={
            <PrivateRoute
              {...defaultAdminRouteProps}
              adminOnly
              outlet={route.Outlet}
            />
          }
        />
      )),
      ...Object.values(routes.mfa).map((route, index) => (
        <Route
          key={index}
          path={getRouterPathFromRoute(route)}
          element={<MfaRoute outlet={route.Outlet} />}
        />
      )),
      <Route
        path="/*"
        element={<Navigate to={routes.private.patientsOverview.href} replace />}
      />,
    ]),
  );

const App = () => {
  const dispatch = useAppDispatch();
  const pathname = window.location.pathname;

  const requiresMfa = useAppSelector((state) => state.mfa.requiresMfa);
  const {
    isAuthenticated,
    accountId,
    hasSingleProfile,
    hasBeenRedirectedOnLogin,
    token,
    canReceiveBrowserNotifications,
  } = useAppSelector((state) => state.user);

  const [getProfile, { isLoading: isProfileLoading }] =
    useLazyGetProfileQuery();

  useEffect(() => {
    if (
      (!isAuthenticated || requiresMfa === null) &&
      pathname.split('/')[1] !== 'mfa'
    ) {
      dispatch(logout());
    }

    if (accountId && !requiresMfa) {
      getProfile({ id: accountId }).then((result) => {
        if ((result as Record<string, unknown>).error) {
          dispatch(logout());
        }
      });
    }

    setupListeners(store.dispatch);

    document.addEventListener('deviceready', () => {
      document.BCFcm?.init({
        apiBaseUrl: config.REACT_APP_API_URL,
        bhKey: config.REACT_APP_BH_KEY,
        bhAuthorization: token,
        accountId,
      });

      if (isAuthenticated) {
        document.BCFcm?.registerDevice();
      }
    });
  }, []);

  useEffect(() => {
    if (canReceiveBrowserNotifications && accountId && token) {
      setupNotifications({ accountId, bhToken: token });
    }
  }, [canReceiveBrowserNotifications]);

  useEffect(() => {
    if (isAuthenticated && hasSingleProfile && !hasBeenRedirectedOnLogin) {
      dispatch(setHasBeenRedirectedOnLogin(true));
    }
  }, [isAuthenticated, hasSingleProfile]);

  const isAppLoading = isProfileLoading;

  if (isAppLoading) {
    return <VLoader isLoading />;
  }

  return <RouterProvider router={router} />;
};

export default App;
