import { Paper, Step, StepContent, StepLabel, Stepper } from '@mui/material';
import { useState } from 'react';
import { Finish, Form, PublicIdentifier } from './steps';

const SendWelcomeEmail = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [accountId, setAccountId] = useState(-1);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handlePublicIdentifierFinish = (
    accountId: number,
    firstName: string,
    lastName: string,
  ) => {
    setAccountId(accountId);
    setFirstName(firstName);
    setLastName(lastName);
    handleNext();
  };

  const handleFormFinish = () => {
    handleNext();
  };

  return (
    <Paper sx={{ padding: 4 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step>
          <StepLabel>Search by the public identifier</StepLabel>
          <StepContent>
            <PublicIdentifier onFinish={handlePublicIdentifierFinish} />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Complete the form</StepLabel>
          <StepContent>
            <Form
              accountId={accountId}
              firstName={firstName}
              lastName={lastName}
              onFinish={handleFormFinish}
              onGoBack={handleBack}
            />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Finish</StepLabel>
          <StepContent>
            <Finish onReset={handleReset} />
          </StepContent>
        </Step>
      </Stepper>
    </Paper>
  );
};

export default SendWelcomeEmail;
