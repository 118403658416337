import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';
import { useLazyGetDashboardDataQuery } from 'store/api/dashboard.api';
import {
  useGetProfileQuery,
  useStartTrackingMutation,
  useStopTrackingMutation,
} from 'store/api/profiles.api';
import {
  startTracking,
  stopTracking,
} from 'store/reducers/dashboard/dashboard.slice';
import { LocationStats } from 'types/patient';
import { TrackingStatus } from 'types/tracking';

const REFETCH_TIME = 10000;

interface Props {
  accountId?: number;
  trackingEndingOn?: string;
}

export const useTracking = ({ accountId, trackingEndingOn }: Props) => {
  const dispatch = useAppDispatch();

  const isTracking = useAppSelector((state) => state.dashboard.isTracking);

  const { data: profileData } = useGetProfileQuery(
    { id: accountId as number },
    { skip: !accountId, refetchOnMountOrArgChange: true },
  );

  const [status, setStatus] = useState<TrackingStatus>(TrackingStatus.IDLE);
  const [lastUpdated, setLastUpdated] = useState<null | string>(null);
  const [location, setLocation] = useState<null | LocationStats>(null);
  const [endingOn, setEndingOn] = useState<null | string>(
    trackingEndingOn ?? null,
  );
  const [fetchCount, setFetchCount] = useState(0);

  const [startTrackingMutation] = useStartTrackingMutation();
  const [stopTrackingMutation] = useStopTrackingMutation();
  const [getDashboardData] = useLazyGetDashboardDataQuery();

  useEffect(() => {
    if (profileData?.result.is_emergency_tracking) {
      dispatch(startTracking());
    }

    if (isTracking && !profileData?.result.is_emergency_tracking) {
      dispatch(stopTracking());
    }
  }, [profileData?.result.id]);

  useEffect(() => {
    const dashboardFetchInterval = setInterval(() => {
      if (isTracking && accountId) {
        if (dayjs().diff(dayjs(endingOn)) > 0) {
          onTrackingEnd();
        }

        if (fetchCount > 10 && status === TrackingStatus.CONNECTING) {
          onTrackingEnd();
          toast(
            'No new location was found for the patient after 10 attempts to fetch it. Please try again.',
            { type: 'error' },
          );
        }

        getDashboardData({ account_id: accountId })
          .unwrap()
          .then((data) => {
            if (data) {
              const newLocation = data.location;
              if (!newLocation) {
                return;
              }

              if (status === TrackingStatus.CONNECTING) {
                setStatus(TrackingStatus.TRACKING);
                setEndingOn(dayjs().add(15, 'minutes').toISOString());
              }

              if (!location) {
                setLocation(newLocation);
                setLastUpdated(dayjs().toISOString());

                return;
              }

              if (
                newLocation.lastUpdate &&
                lastUpdated &&
                dayjs(newLocation.lastUpdate).isAfter(lastUpdated)
              ) {
                setLocation(newLocation);
                setLastUpdated(dayjs().toISOString());
              }
            }
          });
      }

      setFetchCount((prev) => prev + 1);
    }, REFETCH_TIME);

    return () => {
      clearInterval(dashboardFetchInterval);
    };
  }, [isTracking, fetchCount]);

  useEffect(() => {
    setStatus(isTracking ? TrackingStatus.TRACKING : TrackingStatus.IDLE);
  }, [isTracking]);

  useEffect(() => {
    if (dayjs().isBefore(trackingEndingOn)) {
      setEndingOn(trackingEndingOn ?? null);
    }
  }, [trackingEndingOn]);

  const onTrackingStart = () => {
    if (accountId) {
      setStatus(TrackingStatus.CONNECTING);
      setFetchCount(0);
      startTrackingMutation({ id: accountId }).then((response) => {
        if ((response as Record<string, unknown>).error) {
          onTrackingEnd();
          toast('There was an error. Please try again.', { type: 'error' });

          return;
        }

        if ((response as Record<string, unknown>).data) {
          dispatch(startTracking());
        }
      });
    }
  };

  const onTrackingEnd = () => {
    if (accountId) {
      stopTrackingMutation({ id: accountId });
      dispatch(stopTracking());
      setStatus(TrackingStatus.IDLE);
      setEndingOn(null);
    }
  };

  return {
    status,
    location,
    lastUpdated,
    endingOn,
    onTrackingStart,
    onTrackingEnd,
  };
};
