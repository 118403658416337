import { Box, Tab, Tabs } from '@mui/material';
import { PageContent } from 'components/_extra/generic';
import { createContext, useEffect, useState } from 'react';
import { CaregiverGroup } from './CaregiverGroup';
import { BCIdentifier } from './BCIdentifier';
import { ProfileSettings } from './ProfileSettings';
import { AlertSetup } from './AlertSetup';
import { ActivationDate } from './ActivationDate';
import { Caregiver } from './hooks/use-add-caregiver';
import { SafeZoneParams } from 'types/safe-zones';
import { RefreshPatternType } from 'models/devices.model';
import { useLazyGetProfileToConfigureQuery } from 'store/api/admin.api';
import { useAdminStatusQuery } from 'store/api/auth.api';
import { useAppSelector } from 'store';
import { useGetProfileQuery } from 'store/api/profiles.api';
import { VLoader } from 'components/VLoader';
import { Navigate } from 'react-router-dom';
import { routes } from 'nav';
import { useNavigationBlocker } from 'hooks/use-navigation-blocker';

export type ProfileConfiguration = {
  bcIdentifier?: string;
  profileSettings: {
    id?: number;
    firstName: string;
    lastName?: string;
    customIdentifier?: string;
    collectsHealthData: boolean;
    collectsLocationData: boolean;
    showRequestHelpButton: boolean;
    showEmergencyButton: boolean;
    perseverativeMotionTargetDuration?: number;
    locationRefreshPatternType: RefreshPatternType;
    healthRefreshPatternType: RefreshPatternType;
    emergencyContactEmail?: string;
  };
  caregivers: Caregiver[];
  regions: SafeZoneParams[];
  alertTemplates: {
    alertTemplateType: string;
    templateParams?: Record<string, number>;
  }[];
  activationDate: string;
  watchOnly?: boolean;
};

export const DEFAULT_ALERT_TEMPLATES = [
  { alertTemplateType: 'watch_disconnected' },
  { alertTemplateType: 'watch_misconfigured' },
  {
    alertTemplateType: 'watch_low_battery',
    templateParams: { battery_level: 30 },
  },
];

const defaultProfileConfiguration: ProfileConfiguration = {
  profileSettings: {
    firstName: '',
    collectsHealthData: true,
    collectsLocationData: true,
    showRequestHelpButton: true,
    showEmergencyButton: false,
    locationRefreshPatternType: 'hybrid',
    healthRefreshPatternType: 'hybrid',
  },
  caregivers: [],
  regions: [],
  alertTemplates: DEFAULT_ALERT_TEMPLATES,
  activationDate: '',
};

const PROFILE_CREATOR_STEPS = {
  IDENTIFIER: 0,
  CAREGIVER_GROUP: 1,
  SETTINGS: 2,
  ALERT_SETUP: 3,
  ACTIVATION_DATE: 4,
};

export const ProfileConfiguratorContext = createContext({
  handleNext: () => {
    // empty default function
  },
  handlePrevious: () => {
    // empty default function
  },
  profileConfiguration: defaultProfileConfiguration,
  setProfileConfiguration: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (profileSettings: ProfileConfiguration) => ProfileConfiguration,
  ) => {
    // empty default function
  },
  identifierErrorMessage: '',
  reset: () => {
    // empty default function
  },
});

const getTabPanelContent = (index: number) => {
  switch (index) {
    case PROFILE_CREATOR_STEPS.IDENTIFIER:
      return <BCIdentifier />;
    case PROFILE_CREATOR_STEPS.CAREGIVER_GROUP:
      return <CaregiverGroup />;
    case PROFILE_CREATOR_STEPS.SETTINGS:
      return <ProfileSettings />;
    case PROFILE_CREATOR_STEPS.ALERT_SETUP:
      return <AlertSetup />;
    case PROFILE_CREATOR_STEPS.ACTIVATION_DATE:
      return <ActivationDate />;
    default:
      <></>;
  }
};

const ProfileCreator = () => {
  const { accountId } = useAppSelector((state) => state.user);

  const { data: profileData, isLoading: isProfileDataLoading } =
    useGetProfileQuery(
      {
        id: accountId as number,
      },
      {
        skip: !accountId,
      },
    );

  const { data: isAdmin, isLoading: isAdminStatusLoading } =
    useAdminStatusQuery(
      {
        userId: accountId as number,
      },
      {
        skip: !accountId,
      },
    );

  const [getProfileData, { isSuccess, data, isError, error }] =
    useLazyGetProfileToConfigureQuery();

  const [activeStep, setActiveStep] = useState(
    PROFILE_CREATOR_STEPS.IDENTIFIER,
  );
  const [profileConfiguration, setProfileConfiguration] = useState(
    defaultProfileConfiguration,
  );
  const [identifierErrorMessage, setIdentifierErrorMessage] = useState('');

  const handleNextStep = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handlePreviousStep = () => {
    setActiveStep((prev) => prev - 1);
  };

  useEffect(() => {
    if (profileConfiguration.bcIdentifier) {
      getProfileData({ identifier: profileConfiguration.bcIdentifier });
    }
  }, [profileConfiguration.bcIdentifier]);

  useEffect(() => {
    if (isSuccess) {
      setProfileConfiguration((prev) => ({
        ...prev,
        profileSettings: {
          ...prev.profileSettings,
          id: data?.result.id,
          firstName: data?.result.first_name ?? '',
          lastName: data?.result.last_name,
          customIdentifier: data?.result.third_party_public_identifier,
        },
      }));
      handleNextStep();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      setIdentifierErrorMessage(
        (error as { status?: number })?.status === 422
          ? 'This profile has already been set up.'
          : 'This identifier doesn’t exist.',
      );
    }
  }, [isError]);

  useNavigationBlocker(activeStep !== PROFILE_CREATOR_STEPS.IDENTIFIER, {
    message: 'If you leave now, you will lose all of your progress.',
    title: 'Are you sure you want to leave the profile configuration setup?',
    variant: 'danger',
    confirmButtonLabel: 'Leave',
  });

  if (isProfileDataLoading || isAdminStatusLoading) {
    return <VLoader />;
  }

  if (!(profileData?.result.can_configure_organization_devices || isAdmin)) {
    return <Navigate to={routes.private.patientsOverview.href} />;
  }

  return (
    <PageContent sx={{ height: '100vh' }}>
      <Tabs
        sx={{
          backgroundColor: ({ palette }) => palette.primary.main,
          minHeight: ({ spacing }) => spacing(7),
          px: {
            xxs: 0,
            md: 2,
          },
        }}
        value={activeStep}
      >
        <Tab
          disabled={activeStep !== PROFILE_CREATOR_STEPS.IDENTIFIER}
          label="BC Identifier"
          value={PROFILE_CREATOR_STEPS.IDENTIFIER}
          sx={{ cursor: 'default' }}
        />
        <Tab
          disabled={activeStep !== PROFILE_CREATOR_STEPS.CAREGIVER_GROUP}
          label="Caregiver Group"
          value={PROFILE_CREATOR_STEPS.CAREGIVER_GROUP}
          sx={{ cursor: 'default' }}
        />
        <Tab
          disabled={activeStep !== PROFILE_CREATOR_STEPS.SETTINGS}
          label="Profile Settings"
          value={PROFILE_CREATOR_STEPS.SETTINGS}
          sx={{ cursor: 'default' }}
        />
        <Tab
          disabled={activeStep !== PROFILE_CREATOR_STEPS.ALERT_SETUP}
          label="Alert Setup"
          value={PROFILE_CREATOR_STEPS.ALERT_SETUP}
          sx={{ cursor: 'default' }}
        />
        <Tab
          disabled={activeStep !== PROFILE_CREATOR_STEPS.ACTIVATION_DATE}
          label="Activation Date"
          value={PROFILE_CREATOR_STEPS.ACTIVATION_DATE}
          sx={{ cursor: 'default' }}
        />
      </Tabs>
      <ProfileConfiguratorContext.Provider
        value={{
          handleNext: handleNextStep,
          handlePrevious: handlePreviousStep,
          profileConfiguration,
          setProfileConfiguration,
          identifierErrorMessage,
          reset: () => {
            setActiveStep(PROFILE_CREATOR_STEPS.IDENTIFIER);
            setProfileConfiguration(defaultProfileConfiguration);
            setIdentifierErrorMessage('');
          },
        }}
      >
        <Box
          pb={{ xxs: 1.5, xs: 2, sm: 3, newMd: 4 }}
          pl={{ xxs: 1.5, xs: 2, sm: 3, newMd: 4 }}
          overflow="auto"
          flexGrow={1}
        >
          {getTabPanelContent(activeStep)}
        </Box>
      </ProfileConfiguratorContext.Provider>
    </PageContent>
  );
};

export default ProfileCreator;
