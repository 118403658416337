import {
  Box,
  Divider,
  MenuItem,
  Select,
  Switch,
  Typography,
} from '@mui/material';
import NavigationButtons from '../NavigationButtons';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ProfileConfiguratorContext } from '../ProfileConfigurator';
import VInput from 'components/VInput';
import { useProfileSettings } from '../hooks/use-profile-settings';
import { HelpIcon } from 'components/HelpIcon';
import LocationData from './LocationData';
import HealthData from './HealthData';
import ConfiguratorSafeZones from './ConfiguratorSafeZones';

const ProfileSettings = () => {
  const {
    handleNext,
    handlePrevious,
    profileConfiguration,
    setProfileConfiguration,
  } = useContext(ProfileConfiguratorContext);
  const { register, formState, getValues, trigger } = useProfileSettings(
    profileConfiguration.profileSettings,
  );

  const [safeZones, setSafeZones] = useState(profileConfiguration.regions);

  const [activeEmergencyOption, setActiveEmergencyOption] = useState<
    'showEmergencyButton' | 'showRequestHelpButton' | null
  >(
    profileConfiguration.profileSettings.showRequestHelpButton
      ? 'showRequestHelpButton'
      : 'showEmergencyButton',
  );

  const emergencyCaregivers = useMemo(
    () =>
      profileConfiguration.caregivers.filter(
        ({ phoneNumber }) => !!phoneNumber,
      ),
    [profileConfiguration.caregivers],
  );

  const handleNextStep = () => {
    setProfileConfiguration((profileConfiguration) => ({
      ...profileConfiguration,
      regions: safeZones,
      profileSettings: getValues(),
    }));
    handleNext();
  };

  const onNextStepClick = () => {
    if (getValues().firstName.length === 0) {
      trigger('firstName');
      return;
    }
    handleNextStep();
  };

  const handlePreviousStep = () => {
    setProfileConfiguration((profileConfiguration) => ({
      ...profileConfiguration,
      regions: safeZones,
      profileSettings: getValues(),
    }));
    handlePrevious();
  };

  const defaultEmergencyContactEmail =
    profileConfiguration.profileSettings.emergencyContactEmail ??
    emergencyCaregivers[0]?.email ??
    '';

  const handleEmergencyOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.checked) {
      setActiveEmergencyOption(
        event.target.name as 'showEmergencyButton' | 'showRequestHelpButton',
      );
    } else {
      setActiveEmergencyOption(null);
    }
  };

  useEffect(() => {
    if (profileConfiguration.profileSettings.firstName) {
      trigger();
    }
  }, []);

  return (
    <Box maxHeight="100%" display="flex" flexDirection="column" gap={2}>
      <Box
        flexGrow={1}
        overflow="auto"
        pr={{ xxs: 1.5, xs: 2, sm: 3, newMd: 4 }}
        pt={{ xxs: 1.5, xs: 2, sm: 3, newMd: 4 }}
      >
        <Typography color="primary" fontWeight={600} mb={1}>
          Patient Profile
        </Typography>
        <Box maxWidth={({ spacing }) => spacing(67)} p={{ xxs: 1, newMd: 2 }}>
          <Box display="flex" gap={2} pb={3} flexWrap="wrap" width="100%">
            <VInput
              disableWhitespace
              label="First name"
              size="medium"
              error={!!formState.errors.firstName}
              helperText={formState.errors.firstName?.message}
              {...register('firstName')}
              placeholder="Enter first name"
              containerSx={({ spacing }) => ({
                minWidth: spacing(26),
                flexGrow: 1,
              })}
            />
            <VInput
              disableWhitespace
              label="Last name"
              size="medium"
              {...register('lastName')}
              placeholder="Enter last name"
              containerSx={{
                minWidth: ({ spacing }) => spacing(26),
                flexGrow: 1,
              }}
            />
          </Box>
          <VInput
            disableWhitespace
            label="Custom identifier"
            size="medium"
            {...register('customIdentifier')}
            placeholder="Patient ID, insurance ID, billing ID or other ID types"
          />
        </Box>
        <Divider sx={{ mt: 2, mb: { xxs: 3, sm: 4 } }} />
        <Typography color="primary" fontWeight={600}>
          Emergency Management
        </Typography>
        <Box
          px={{ xxs: 1, newMd: 2 }}
          py={2}
          display="flex"
          flexDirection="column"
          gap={1}
          maxWidth={536}
        >
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center" gap={1}>
              <Typography color="primary" py={1}>
                Request Help Button
              </Typography>
              <HelpIcon message="Display a button on the Watch screen that the patient can tap to request assistance from caregivers. An alert will be sent to all caregivers, or those you’ve specifically selected in the Alerts section. This option cannot be active at the same time with Emergency Call Button." />
            </Box>
            <Switch
              {...register('showRequestHelpButton', {
                onChange: handleEmergencyOptionChange,
              })}
              defaultChecked={
                profileConfiguration.profileSettings.showRequestHelpButton
              }
              disabled={activeEmergencyOption === 'showEmergencyButton'}
              sx={{
                ml: 2,
              }}
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center" gap={1}>
              <Typography color="primary" py={1}>
                Emergency Call Button
              </Typography>
              <HelpIcon message="Only caregivers marked as “Manager” can change emergency contact settings. This option cannot be active at the same time as the Request Help Button. Add a phone number to a caregiver’s profile to allow them to be selected for an emergency contact." />
            </Box>
            <Switch
              {...register('showEmergencyButton', {
                onChange: handleEmergencyOptionChange,
              })}
              disabled={activeEmergencyOption === 'showRequestHelpButton'}
              defaultChecked={
                profileConfiguration.profileSettings.showEmergencyButton
              }
              sx={{
                ml: 2,
              }}
            />
          </Box>
          <Box display="flex" gap={1} alignItems="center">
            <Select
              size="medium"
              displayEmpty
              defaultValue={defaultEmergencyContactEmail}
              disabled={
                activeEmergencyOption !== 'showEmergencyButton' ||
                emergencyCaregivers.length === 0
              }
              {...register('emergencyContactEmail')}
              sx={({ spacing }) => ({
                maxWidth: spacing(30.5),
                maxHeight: spacing(4),
                flexGrow: 1,
              })}
            >
              <MenuItem value="" disabled>
                None
              </MenuItem>
              {emergencyCaregivers.map((caregiver) => (
                <MenuItem key={caregiver.email} value={caregiver.email}>
                  {caregiver.firstName} {caregiver.lastName}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Divider sx={{ mt: 2, mb: { xxs: 3, sm: 4 } }} />
        <Typography color="primary" fontWeight={600}>
          Data Settings
        </Typography>
        <LocationData
          switchProps={{
            ...register('collectsLocationData'),
            defaultChecked:
              profileConfiguration.profileSettings.collectsLocationData,
          }}
          selectProps={{
            ...register('locationRefreshPatternType'),
            defaultValue:
              profileConfiguration.profileSettings.locationRefreshPatternType,
          }}
        />
        <HealthData
          switchProps={{
            ...register('collectsHealthData'),
            defaultChecked:
              profileConfiguration.profileSettings.collectsHealthData,
          }}
          selectProps={{
            ...register('healthRefreshPatternType'),
            defaultValue:
              profileConfiguration.profileSettings.healthRefreshPatternType,
          }}
        />
        <Divider sx={{ mt: 2, mb: { xxs: 3, sm: 4 } }} />
        <ConfiguratorSafeZones
          defaultSafeZones={profileConfiguration.regions}
          updateSafeZoneList={setSafeZones}
        />
      </Box>
      <NavigationButtons
        onNextClick={onNextStepClick}
        onPreviousClick={handlePreviousStep}
      />
    </Box>
  );
};

export default ProfileSettings;
