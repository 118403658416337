import {
  Box,
  MenuItem,
  OutlinedInput,
  OutlinedInputProps,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { InputBox } from './styled';
import {
  AlternativeComparatorLabel,
  Comparator,
  TriggerType,
  getRuleLabel,
  normalizeTriggerValue,
} from 'utils/triggers';
import {
  ChangeEvent,
  Dispatch,
  FocusEventHandler,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { TimeInput } from 'components/TimeInput';

interface Props {
  selectedType: TriggerType;
  inCard?: boolean;
  readOnly?: boolean;
  defaultValue?: number;
  defaultComparator?: string;
  value?: number;
  rule: string;
  setRule: Dispatch<SetStateAction<string>>;
  setValue?: Dispatch<SetStateAction<number>>;
  onFocus?: () => void;
  alternativeComparatorLabels?: AlternativeComparatorLabel;
}

interface NumericInputProps extends OutlinedInputProps {
  comparatorOptions: Comparator[];
  setValue: (v: number) => void;
  unit?: string;
  value: number;
  withValue?: boolean;
  type: string;
  min?: number;
  max?: number;
  step?: number;
  readOnly?: boolean;
  onFocus?: () => void;
}

const getRuleOptions = (
  rule: Comparator,
  selectedType?: TriggerType,
  alternativeComparatorLabels?: AlternativeComparatorLabel,
) => {
  if (alternativeComparatorLabels?.[rule]) {
    return (
      <MenuItem key={rule} value={rule}>
        {alternativeComparatorLabels[rule]}
      </MenuItem>
    );
  }

  switch (rule) {
    case Comparator.NOT_EQUAL:
    case Comparator.EQUAL:
      return selectedType?.additionalOptions
        ?.filter((addOpt) => addOpt.comparator === rule)
        .map((opt) => (
          <MenuItem value={opt.value} key={opt.value}>
            {opt.label}
          </MenuItem>
        ));
    case Comparator.CHANGE:
    case Comparator.EVENT:
    case Comparator.GREATER_THAN:
    case Comparator.LESS_THAN:
    default:
      return (
        <MenuItem key={rule} value={rule}>
          {getRuleLabel(rule)}
        </MenuItem>
      );
  }
};

const NumericInput = ({
  comparatorOptions,
  unit,
  readOnly = false,
  withValue,
  setValue,
  type,
  value,
  min,
  max,
  step,
  onFocus,
  ...rest
}: NumericInputProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!isNaN(parseFloat(e.target.value))) {
      setValue(
        e.target.value.length > 0 ? parseFloat(e.target.value || '0') : 0,
      );
      rest.onChange?.(e);
    }
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = () => {
    setValue(normalizeTriggerValue(value, min, max));
  };

  const handleTimeChange = (seconds: number) => {
    setValue(seconds);
  };

  if (type === 'daily_sleep_time') {
    return (
      <InputBox>
        <TimeInput
          value={value}
          readOnly={readOnly}
          onChange={handleTimeChange}
          onFocus={onFocus}
        />
        <Typography>HH:MM</Typography>
      </InputBox>
    );
  }

  if (
    comparatorOptions.includes(Comparator.GREATER_THAN) ||
    comparatorOptions.includes(Comparator.LESS_THAN) ||
    (comparatorOptions.includes(Comparator.EVENT) && withValue)
  ) {
    return (
      <InputBox>
        <OutlinedInput
          type="number"
          readOnly={readOnly}
          size="small"
          {...rest}
          inputProps={{
            min,
            max,
            step,
          }}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={onFocus}
          value={value.toString()}
        />
        <Typography>{unit}</Typography>
      </InputBox>
    );
  }

  return null;
};

const RuleWrapper = ({
  children,
  inCard,
}: {
  inCard: boolean;
  children: ReactNode;
}) => {
  return (
    <Box display="flex" gap={1.5} pb={1} flexWrap={inCard ? 'wrap' : 'nowrap'}>
      {children}
    </Box>
  );
};

const Rule = ({
  selectedType,
  inCard,
  readOnly = false,
  setRule: setRuleExternally,
  setValue: setValueExternally,
  rule: externalRule,
  value: externalValue,
  defaultComparator,
  defaultValue,
  alternativeComparatorLabels,
  onFocus,
}: Props) => {
  const [rule, setRule] = useState<string>(defaultComparator ?? 'change');

  const [numericValue, setNumericValue] = useState<number>(defaultValue ?? 0);
  const [options, setOptions] = useState<Comparator[]>(
    selectedType.comparatorOptions,
  );

  const setRuleGlobally = (value: string) => {
    setRule(value);
    setRuleExternally(value);
  };

  const setValueGlobally = (value: number) => {
    setNumericValue(value);
    setValueExternally?.(value);
  };

  const handleChange = (e: SelectChangeEvent<string>) => {
    setRuleGlobally(e.target.value);
  };

  useEffect(() => {
    setOptions(selectedType.comparatorOptions);
  }, [selectedType]);

  useEffect(() => {
    if (externalRule !== rule) {
      setRule(externalRule);
    }

    if (externalValue !== numericValue) {
      setNumericValue(
        normalizeTriggerValue(
          externalValue ??
            selectedType.defaults?.[rule] ??
            selectedType.min ??
            0,
          selectedType.min,
          selectedType.max,
        ),
      );
    }
  }, [externalRule, externalValue]);

  return (
    <RuleWrapper inCard={!!inCard}>
      <Box flexGrow={1} flexBasis={0}>
        <Typography pb={1} color="primary">
          Rule
        </Typography>
        <Select
          sx={{ width: '100%' }}
          size="medium"
          value={rule}
          onChange={handleChange}
          readOnly={readOnly}
          onOpen={onFocus}
        >
          {options.map((rule) =>
            getRuleOptions(rule, selectedType, alternativeComparatorLabels),
          )}
        </Select>
      </Box>
      <NumericInput
        comparatorOptions={selectedType.comparatorOptions}
        unit={selectedType.unit}
        readOnly={readOnly}
        withValue={selectedType.withValue}
        value={numericValue}
        setValue={setValueGlobally}
        type={selectedType.value}
        min={selectedType.min}
        max={selectedType.max}
        step={selectedType.step}
        onFocus={onFocus}
      />
    </RuleWrapper>
  );
};

export default Rule;
