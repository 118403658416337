import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useMemo } from 'react';
import { PresetOption, getMatchingPreset, getPreset } from 'utils/weekdays';

interface Props {
  onChange: (selectedValue: number[]) => void;
  checkedElements: number[];
  disabled?: boolean;
  fullWidth?: boolean;
  readOnly?: boolean;
}

const PresetSelect = ({
  disabled,
  onChange,
  checkedElements,
  fullWidth,
  readOnly,
}: Props) => {
  const handleChange = (event: SelectChangeEvent<PresetOption>) => {
    onChange(getPreset(event.target.value as PresetOption));
  };

  const preset = useMemo(() => {
    return getMatchingPreset(checkedElements);
  }, [checkedElements]);

  return (
    <Select
      value={preset}
      size="medium"
      onChange={handleChange}
      disabled={disabled}
      fullWidth={fullWidth}
      readOnly={readOnly}
      sx={({ spacing }) => ({
        minWidth: fullWidth ? undefined : spacing(16),
        maxWidth: fullWidth ? undefined : spacing(37.5),
        flexGrow: 1,
      })}
    >
      <MenuItem value={PresetOption.EVERYDAY}>Everyday</MenuItem>
      <MenuItem value={PresetOption.WEEKDAYS}>Weekdays</MenuItem>
      <MenuItem value={PresetOption.WEEKEND}>Weekend</MenuItem>
      <MenuItem value={PresetOption.CUSTOM}>Custom</MenuItem>
    </Select>
  );
};

export default PresetSelect;
