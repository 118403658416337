import { yupResolver } from '@hookform/resolvers/yup';
import { RefreshPatternType } from 'models/devices.model';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

type Profile = {
  firstName: string;
  lastName?: string;
  customIdentifier?: string;
  collectsHealthData: boolean;
  collectsLocationData: boolean;
  showRequestHelpButton: boolean;
  showEmergencyButton: boolean;
  perseverativeMotionTargetDuration?: number;
  locationRefreshPatternType: RefreshPatternType;
  healthRefreshPatternType: RefreshPatternType;
  emergencyContactEmail?: string;
};

const schema = yup.object({
  firstName: yup.string().required('First name is required.'),
  lastName: yup.string(),
  customIdentifier: yup.string(),
});

export const useProfileSettings = (defaultValues?: Profile) => {
  const { register, formState, getValues, trigger } = useForm<Profile>({
    resolver: yupResolver(schema),
    defaultValues,
  });
  return { register, formState, getValues, trigger };
};
