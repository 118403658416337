import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { routes } from 'nav';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { authApi } from 'store/api/auth.api';
import { profilesApi } from 'store/api/profiles.api';
import { deregisterDevice } from 'utils/init-firebase';

type UserState = {
  email?: string;
  phone?: string;
  accountId?: number;
  credentialId?: number;
  token?: string;
  name?: string;
  isAuthenticated: boolean;
  isMfaPassed: boolean;
  mfaPassRedirectRoute: string;
  hasProfiles: boolean;
  hasSingleProfile: boolean;
  hasBeenRedirectedOnLogin: boolean | null;
  canReceiveBrowserNotifications?: boolean;
};

const initialState: UserState = {
  isAuthenticated: false,
  isMfaPassed: false,
  mfaPassRedirectRoute: routes.private.patientsOverview.href,
  hasProfiles: true,
  hasSingleProfile: false,
  hasBeenRedirectedOnLogin: false,
};

export const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout(state) {
      if (state.token) {
        deregisterDevice({ bhToken: state.token });
      }
      state.token = undefined;
      state.isAuthenticated = false;
      state.isMfaPassed = false;
      storage.removeItem('persist:user');
      storage.removeItem('persist:dashboard');
    },
    setAuthSuccess(state) {
      state.isAuthenticated = true;
    },
    setName(
      state,
      { payload }: PayloadAction<{ firstName?: string; lastName?: string }>,
    ) {
      state.name = [payload.firstName ?? '', payload.lastName ?? '']
        .join(' ')
        .trim();
    },
    setHasBeenRedirectedOnLogin(state, { payload }: PayloadAction<boolean>) {
      state.hasBeenRedirectedOnLogin = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        authApi.endpoints.authenticate.matchFulfilled,
        (state, action) => {
          state.email = action.payload.meta.credential.identifier;
          state.name = action.payload.meta.credential.schema;
          state.accountId = action.payload.meta.credential.account_id;
          state.credentialId = action.payload.meta.credential.id;
          state.token = action.payload.result.token;
          state.mfaPassRedirectRoute = routes.private.patientsOverview.href;

          document.BCFcm?.init({
            bhAuthorization: action.payload.result.token,
            accountId: action.payload.meta.credential.account_id,
          });
          document.BCFcm?.registerDevice();
        },
      )
      .addMatcher(
        authApi.endpoints.submitPasswordResetToken.matchFulfilled,
        (state, action) => {
          const oneTimeToken = action.meta.arg.originalArgs.token;

          state.email = action.payload.meta.credential.identifier;
          state.accountId = action.payload.meta.credential.account_id;
          state.credentialId = action.payload.meta.credential.id;
          state.token = action.payload.result.token;
          state.mfaPassRedirectRoute = `${routes.public.setNewPassword.href}?token=${oneTimeToken}`;
        },
      )
      .addMatcher(
        authApi.endpoints.validateCode.matchFulfilled,
        (state, action) => {
          state.accountId = action.payload.meta.credential.account_id;
          state.isMfaPassed = true;
          state.token = action.payload.result.token;
        },
      )
      .addMatcher(
        profilesApi.endpoints.getLinkedProfiles.matchFulfilled,
        (state, action) => {
          if (action.payload.results.length > 0) {
            state.hasProfiles = true;
            state.hasSingleProfile = action.payload.results.length === 1;
            return;
          }

          state.hasProfiles = false;
        },
      )
      .addMatcher(
        profilesApi.endpoints.getProfile.matchFulfilled,
        (state, action) => {
          if (state.accountId === action.payload.result.id) {
            state.canReceiveBrowserNotifications =
              action.payload.result.can_receive_browser_notifications;
          }
        },
      );
  },
});

export const { logout, setName, setAuthSuccess, setHasBeenRedirectedOnLogin } =
  UserSlice.actions;

export default persistReducer(
  {
    key: 'user',
    storage,
    whitelist: [
      'id',
      'name',
      'accountId',
      'credentialId',
      'token',
      'requiresMfa',
      'email',
      'phone',
      'isAuthenticated',
    ],
  },
  UserSlice.reducer,
);
