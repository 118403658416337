// 'upi' stands for Unique Profile Identifier
// 'tp-upi' stands for Third-Party Unique Profile Identifier

export type SearchByOption =
  | 'email'
  | 'profileId'
  | 'phone'
  | 'name'
  | 'imei'
  | 'upi'
  | 'tpUpi';

export const selectOptions: { displayName: string; value: SearchByOption }[] = [
  {
    displayName: 'Email',
    value: 'email',
  },
  {
    displayName: 'IMEI',
    value: 'imei',
  },
  {
    displayName: 'Name',
    value: 'name',
  },
  {
    displayName: 'Phone',
    value: 'phone',
  },
  {
    displayName: 'Profile ID',
    value: 'profileId',
  },
  {
    displayName: 'Public Profile Identifier',
    value: 'upi',
  },
  {
    displayName: 'Third-Party Profile Identifier',
    value: 'tpUpi',
  },
];

export interface LookupTableRow {
  id: number;
  name?: string;
  email?: string;
  phoneNumber?: string;
  isLovedOne: boolean;
}
