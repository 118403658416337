import { Box, Button, useTheme } from '@mui/material';
import VInput from 'components/VInput';
import { ISearchByUpiRes } from 'models/admin.model';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useSearchByPublicIdentifierMutation } from 'store/api/admin.api';

interface Props {
  onFinish: (accountId: number, firstName: string, lastName: string) => void;
}

const PublicIdentifier = ({ onFinish }: Props) => {
  const { spacing } = useTheme();

  const [identifier, setIdentifier] = useState('');

  const [search, { isLoading }] = useSearchByPublicIdentifierMutation();

  const onSubmit = () => {
    search({ identifier }).then((result) => {
      if ((result as Record<string, unknown>).error) {
        return toast('An error has occurred.', { type: 'error' });
      }

      const resultCast = (result as Record<string, unknown>)
        .data as ISearchByUpiRes;

      if (resultCast.results.patient_profiles.length < 1) {
        return toast(
          'No patient profiles could be fetched using this identifier.',
          { type: 'error' },
        );
      }

      const profile = resultCast.results.patient_profiles[0];

      onFinish(profile.id, profile.first_name, profile.last_name);
    });
  };

  return (
    <Box>
      <VInput
        label="Enter the public identifier"
        containerSx={{ maxWidth: spacing(30) }}
        type="string"
        value={identifier}
        onChange={(e) => setIdentifier(e.target.value)}
        disabled={isLoading}
      />
      <Button onClick={onSubmit} disabled={isLoading}>
        Next
      </Button>
    </Box>
  );
};

export default PublicIdentifier;
